import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default () => (
  <Layout currentPath='/terms-and-conditions/' light={true} fullPage={true}>
    <SEO title="Terms and Conditions" />
    <div className="container terms">
      <h1>Terms and Conditions</h1>
      <p>These terms and conditions of Services shall apply to all offers, legal relationships and agreements under which
  Fiboo provides services.</p>
<ol>
  <li>
    <p>Definitions</p>
    <p>Agreement: The agreement with respect to the Services between Fiboo and Customer, including these Terms and
      Conditions and all other annexes or exhibits;</p>
    <p>Fiboo: Fiboo, with corporate domicile in the TR, at İTÜ Ayazağa Kampüsü, ARI 1 Teknokent, No: 8,
      Maslak/İstanbul</p>
    <p>Errors: a substantial failure to meet the functional specifications expressly agreed upon in the Agreement.</p>
    <p>Maintenance Release: a periodic release of the Software provided by Fiboo at its sole discretion, which
      incorporates problem resolutions and which will usually not contain functional enhancements;</p>
    <p>Services: any and all services specified in the Agreement, including but not limited to development,
      installation, consulting or maintenance and support services;</p>
    <p>Software: the software of Fiboo specified in the Agreement and provided to Customer pursuant to another
      agreement between Fiboo and the Customer;</p>
    <p>Upgrade: a new version of the Software by Fiboo at its sole discretion, which may contain new features or
      functional changes to the Software.</p>
  </li>
  <li>
    <p>Performance of the Services</p>
    <ol>
      <li>
        <p>Subject to the terms and conditions of the Agreement and in consideration of the payment of all applicable
          fees, Fiboo will, to the best of its ability, do its utmost to perform the Services with due care and in
          accordance with the Agreement. The Services shall be performed on the basis of a best efforts obligation,
          unless and insofar as Fiboo has expressly promised a result in the written Agreement and the result
          concerned has also been described with sufficient definiteness. Any agreements concerning a service level must
          always be expressly agreed in writing.</p>
      </li>
      <li>
        <p>If the Services are provided in stages, Fiboo shall be entitled to postpone the start of the Services
          which are part of a stage until Customer has approved the results of the preceding stage in writing.</p>
      </li>
      <li>
        <p>In the event that employees of Fiboo perform work on the premises of Customer, Customer shall provide
          appropriate working conditions, access to all necessary facilities including but not limited to computer and
          telecommunication facilities and qualified personnel as may be reasonably requested by Fiboo. All
          facilities shall comply with all applicable statutory and other requirements and provisions concerning working
          conditions. Customer shall notify the employees deployed by Fiboo of any applicable company rules or
          security rules prior to the commencement of the activities. Customer shall indemnify Fiboo against claims
          by third parties, including the Fiboo’s employees, who, in executing the Agreement, suffer injury which is
          the result of acts or omissions by Customer or of unsafe situations in its organization.</p>
      </li>
      <li>
        <p>All delivery and other terms stated in the Agreement only serve as guidelines. Fiboo will use its best
          effort to observe the agreed delivery and other periods, however these (delivery) periods are never fatal,
          unless explicitly stated otherwise. Unless explicitly otherwise agreed upon in Agreement, the amount of
          Services and hours stated in the Agreement serve only as an estimation of the total amount of time needed for
          the project. Fiboo will always charge the Customer for the actual Services provided.</p>
      </li>
      <li>
        <p>Fiboo is entitled to sub-contract any part of its obligations with respect to the Services to a third
          party, for example a local partner, without the prior written consent of Customer.</p>
      </li>
    </ol>
  </li>
  <li>
    <p>Additional work and modification of the Services</p>
    <ol>
      <li>
        <p>In performing the Services, Fiboo shall only be obliged to follow timely, reasonable and sensible
          instructions of Customer if this has been expressly agreed in writing. Fiboo shall not be required to
          follow instructions which change or supplement the substance or scope of the agreed Services. If such
          instructions are being followed, however, the work in question shall be compensated pursuant to article 3.2
        </p>
      </li>
      <li>
        <p>If, at the request of or with prior consent from Customer, Fiboo has performed work or rendered other
          performance which goes beyond the substance or scope of the Services, the Customer shall pay for that work or
          performance according to the Fiboo’s usual rates. Expanding or modifying a system analysis, a design or
          specifications shall also constitute additional work. Fiboo shall never be obliged to satisfy such a
          request, and it may require that a separate purchase order has to be concluded. Upon request, Fiboo shall
          inform Customer in writing in advance about the financial consequences of the extra work or performance.</p>
      </li>
      <li>
        <p>Customer accepts that work or performance as referred to in article 3.2 may affect the agreed or expected
          time of completion of the Services and the mutual responsibilities of Fiboo and Customer. The fact that
          additional work (or the demand for it) arises during execution of the Agreement shall never be a ground for
          Customer to rescind or terminate the Agreement.</p>
      </li>
    </ol>
  </li>
  <li>
    <p>Personnel</p>
    <ol>
      <li>
        <p>Fiboo guarantees that all personnel engaged by it in the performance of the Services shall have the
          required level of skill and expertise as can reasonably be expected by Customer. Fiboo may constitute or
          modify the team that carries out the Services in accordance with its own insights. Customer may not stipulate
          particular Fiboo staff-members.</p>
      </li>
      <li>
        <p>Fiboo has the right to use third parties in the execution of the Agreement without prior consent of
          Customer. All personnel of third parties used in connection with the Agreement, will be considered personnel
          of Fiboo in the context of the Agreement. Fiboo will impose the terms of the Agreement to these third
          parties and will see to it that they will fulfill the obligation inserted herein.</p>
      </li>
    </ol>
  </li>
  <li>
    <p>Maintenance and Support</p>
    <ol>
      <li>
        <p>The maintenance and support services consist of the repair of Errors or taking such measures, including but
          not limited to the replacement of Software or installing temporarily solutions such as a work-around, bypass
          or problem preventing restrictions, that the Customer continue to use the functionality of the Software.
          Fiboo does not guarantee that the Software will work without Errors or that Errors will be improved.
          Fiboo will support only the current and previous release of the Software starting with the version agreed
          upon in writing.</p>
      </li>
      <li>
        <p>After the receipt of a written notice of an Error, Fiboo shall use its best effort to correct the Error
          or make corrections in any later Maintenance Release. Fiboo will make the results available to Customer in
          a manner, place and at a time to be determined by Fiboo. Fiboo’s obligations with respect to the
          correction of Errors, are conditional upon the Error being reproducible. Customer therefore uses its best
          endeavours to provide a full description of the Error and any other such information or document which
          facilitates Fiboo in reproducing the occurred error and solving the Software Problem. The Customer shall
          use best endeavours to analyse the cause of the Error.</p>
      </li>
      <li>
        <p>Fiboo maintenance and support obligations under the Agreement shall cease if the non-operation of the
          Software has been caused by any factor outside the control of Fiboo, including but not limited to (i) any
          problems caused by reparation, modification, configuration, reinstallation or relocation of the Software not
          authorized and/or performed by Fiboo; (ii) any problem with the functioning or the use of the external
          system or software or the hardware configuration, including without limitation the operating system and/or
          hardware upgrades; (iii) the operation or use of the Software in a way that is not accordance with the latest
          release of the relevant documentation; (iv) support related to third party software or hardware not supplied
          by Fiboo; or (v) abuse, misuse or mishandling of the Software.</p>
      </li>
      <li>
        <p>Change requests do not fall under the scope of the maintenance and support services.</p>
      </li>
      <li>
        <p>If agreed upon in the Agreement, Customer will receive at no additional cost one copy of each Maintenance
          Release or Upgrade issued by Fiboo during the term of the maintenance and support part of the Agreement.
          The Maintenance Release and Upgrade shall be delivered to Customer on the media and in the format to be
          decided by Fiboo. Customer agrees to incorporate said Maintenance Release or Upgrade only into the
          installed Software provided it first passes Customer’s acceptance tests. The Maintenance Releases and Upgrade
          shall be considered part of the Software and, accordingly, covered by the scope of the EULA.</p>
      </li>
      <li>
        <p>At all times, Customer is personally responsible for making and keeping back-ups of the configuration and the
          data. Reconfiguration existing sets of data or defining new sets of data is not part of the maintenance and
          support service, unless explicitly agreed upon in writing.</p>
      </li>
      <li>
        <p>Should the Software or Product not be operable due to reasons outside the control of Fiboo, Customer
          shall be liable to pay Fiboo in accordance with then valid hourly rates. Fiboo shall, however, be
          under no obligation to provide services when the Software is not operable due to reasons outside its control.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p>Cooperation of Customer</p>
    <ol>
      <li>
        <p>On request of Fiboo, Customer shall furnish Fiboo with all data, information, hardware, facilities,
          and materials that are reasonably necessary to execute the Agreement properly, provide access to the relevant
          software, system or other accounts and provide all other reasonable full cooperation. If Customer utilizes its
          own employees in cooperating in the execution of the Agreement, these employees shall possess the necessary
          know-how, experience, training and abilities.</p>
      </li>
      <li>
        <p>If Customer does not provide Fiboo with the requested data, equipment, software, materials or other
          cooperation necessary to execute the Agreement, or does not provide this in a timely manner or in accordance
          with the agreements made, or if Customer otherwise does not fulfil its obligations under the Agreement,
          Fiboo shall be entitled to suspend execution of the Agreement in whole or in part, and it shall be
          entitled to charge the ensuing expenses in accordance with its usual rates, all of this without prejudice to
          Fiboo’s right to exercise any other legal right.</p>
      </li>
      <li>
        <p>If and in so far Customer provide Fiboo with personal data, it guarantees that with regard to such
          personal data, all applicable regulations for the protection of privacy have been observed and that Fiboo
          is entitled to process such personal data. Customer indemnifies Fiboo against any third parties’ claims in
          this respect. Fiboo will ensure that it on its own side will also strictly observe all applicable privacy
          regulations.</p>
      </li>
      <li>
        <p>In the event that employees of Fiboo perform work on the premises of Customer, Customer shall provide
          appropriate working conditions, access to all necessary facilities including but not limited to computer and
          telecommunication facilities and qualified personnel as may be reasonably requested by Fiboo. All
          facilities shall comply with all applicable statutory and other requirements and provisions concerning working
          conditions. The Customer shall indemnify Fiboo against claims by third parties, including the Fiboo’s
          employees, who, in executing the Agreement, suffer injury which is the result of acts or omissions by the
          Customer or of unsafe situations in its organization.</p>
      </li>
    </ol>
  </li>
  <li>
    <p>Orders, fees and payment</p>
    <ol>
      <li>
        <p>All fees are exclusive taxes (VAT) and other levies imposed by the government. The fees are exclusive travel
          or living expenses or other external costs made by Fiboo in the course of carrying out the Services. These
          expenses and costs shall be charged separately.</p>
      </li>
      <li>
        <p>Fiboo is entitled to request Customer to make an advance payment. This advance payment will be set off
          against the actual invoice(s).</p>
      </li>
    </ol>
  </li>
  <li>
    <p>Intellectual property rights</p>
    <ol>
      <li>
        <p>All Intellectual Property Rights with regard to the Software and/or Services, including all documentation,
          information and any other materials developed and provided by Fiboo under the Agreement (such as analyses
          and designs), as well as preparatory materials in that regard shall be held solely by Fiboo or its
          licensors. Customer shall only acquire the rights of use expressly granted in the Agreement and by law. Any
          other or more extensive right of Customer shall be excluded.</p>
      </li>
      <li>
        <p>Customer will not be entitled to use the trademarks, brand names, logos, trade names, designs or know-how, or
          any other distinctive sign of Fiboo, without its prior written consent.</p>
      </li>
    </ol>
  </li>
  <li>
    <p>Confidentiality and data protection</p>
    <ol>
      <li>
        <p>Parties warrant that all of the information received by them which is known to be or should be known to be
          confidential in nature shall remain secret and shall not be disclosed, unless a legal obligation mandates
          disclosure of that information. Information with a confidential nature includes, but is not limited to,
          information with respect to the object and source code, other technical information, errors, defects, methods
          and procedures necessary for the use of the Software, commercial information and the content of the Agreement.
          Parties shall only use the information for the purpose for which it has been provided. Information shall in
          any event be considered confidential if it is designated by a Party as such. The duty of confidentiality shall
          not apply to information which is already in the public domain at the time of disclosure.</p>
      </li>
      <li>
        <p>Parties will give notice of the commitments deriving from this article to all employees that have access to
          any confidential information from the other Party, demanding full compliance with the obligation inserted in
          this Section. Parties will notify the other Party of any unauthorised use of the confidential information that
          it has had knowledge of by any means.</p>
      </li>
      <li>
        <p>In the event that a party process personal data, it will process such data of a personal nature in a
          confidential manner, appropriately complying at all times with the applicable provisions concerning the
          protection of personal data.</p>
      </li>
    </ol>
  </li>
  <li>
    <p>Term and termination</p>
    <ol>
      <li>
        <p>The Agreement will be valid for the initial term agreed upon in the Agreement. Following the expiration of
          this initial term, the Agreement shall be automatically renewed for consecutive one-year periods, unless
          either party notifies the other in writing at least three months prior to the expiration of the initial term
          or then-current renewal term of its intent not to renew the Agreement.</p>
      </li>
      <li>
        <p>Notwithstanding the term agreed upon in the Agreement, Fiboo shall be entitled to terminate the Agreement
          at any time without liability to the Customer, if the Customer: (i) commits a breach of any of the terms of
          the Agreement (and if such breach is remediable) fails to remedy that breach within 15 (fifteen) days of the
          Customer being notified in writing of the breach; (ii) applies for a moratorium of payments, is declared
          bankrupt or otherwise ceases to meet its (financial) obligations; or (iii) suspends or discontinues its
          business activities.</p>
      </li>
    </ol>
  </li>
  <li>
    <p>Miscellaneous</p>
    <ol>
      <li>
        <p>The Agreement constitutes the full agreement between the parties with respect to the use and license of the
          Software and it replaces any prior agreements concerning the subject hereof. The Agreement can only be
          modified by means of a written agreement signed by the Parties.</p>
      </li>
      <li>
        <p>Customer is not entitled to assign or otherwise transfer any rights or obligations under the Agreement,
          without the prior written consent of Fiboo. Fiboo shall be entitled to assign or otherwise transfer
          its rights and obligations under the Agreement without requiring the consent of Customer.</p>
      </li>
      <li>
        <p>Fiboo retains the right to disclose the identity of any Customer on its website or in any sales or
          funding efforts or processes of Fiboo, however, always with due consideration to the interests of the
          Customer.</p>
      </li>
    </ol>
  </li>
</ol>

    </div>
  </Layout>
)