/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import CookieConsent from "react-cookie-consent";

import Header from "./header"
import { Link } from 'gatsby'

import "./layout.css"
import "../style/style.scss"

import $ from "jquery";

export default class Layout extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset) {
        document.body.classList.add('scrolled');
      } else {
        document.body.classList.remove('scrolled');
      }
    });
  }

  render() {
    const { children, isAnimated, currentPath, light, fullPage } = this.props;
    return (
      <>
        <div className={`content ${fullPage ? 'fullPage' : ''}`}>
          <Header isAnimated={isAnimated} currentPath={currentPath} light={light} />
          <main className="main">{children}</main>
         

          <CookieConsent
            location="bottom"
            buttonText="X"
            cookieName="fiboo_consent"
            style={{ alignItems: "center", backgroundColor: "black", fontSize: "12px" }}
            contentStyle={{ flex: "1" }}
            buttonStyle={{ color: "white", borderRadius: "50%", backgroundColor: "#0064C8" }}
          >
            Fiboo respects your privacy. We use cookies for various purposes, such as improving user experience and ensuring the integration of social media. By continuing your visit to our website, you consent to our use of cookies.
            &nbsp;
            <Link to="/privacy-policy/" className="white-link">Privacy Policy</Link>
          </CookieConsent>
        </div>
      </>
    );
  }
}
