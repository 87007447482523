import { Link } from "gatsby"
import React from "react"
import FibooLogo from './icons/fiboo';

const HeaderMenuItem = ({ path, title, currentPath, isButton }) => (
  <h2 className={`header-menu-item ${path === currentPath ? 'active' : ''} ${isButton ? 'button' : ''}`}>
    <Link to={path}>
      {title}
    </Link>
  </h2>
)

const HeaderMenu = ({ children }) => {
  return (
    <div className="header-menu">
      <input id="headerMobileMenu" type="checkbox" style={{ display:"none" }} />
      <label htmlFor="headerMobileMenu" className="header-mobile-button">
        <div></div>
        <div></div>
        <div></div>
      </label>
      <div className="header-menu-content">
        {children}
      </div>
    </div>
  )
}

const Header = ({ isAnimated, currentPath, light }) => (
  <header className={`header ${isAnimated ? 'slide-down': ''} ${light ? 'light' : ''}`} id="topHeader">
    <div className="container">
      <Link to="/" className="fiboo-logo">
        <FibooLogo light={light} />
      </Link>

      <HeaderMenu>
        <HeaderMenuItem {...{
          path: '/solutions/',
          title: 'Solutions',
          currentPath: currentPath
        }} />

        <HeaderMenuItem {...{
          path: '/clients/',
          title: 'Clients',
          currentPath: currentPath
        }} />

        {/* <HeaderMenuItem {...{
          path: '/team/',
          title: 'Team',
          currentPath: currentPath
        }} /> */}

        <HeaderMenuItem {...{
          path: '/contact/',
          title: 'Contact',
          currentPath: currentPath
        }} />
      </HeaderMenu>
    </div>
    
  </header>
)

export default Header
